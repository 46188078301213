import axios from "axios";
import { createSearchParams } from "react-router-dom";
const dynamicApiUrl = process.env.REACT_APP_API_BASE_URL
const dynamicRouteUrl = process.env.REACT_APP_ROUTE_TO_URL
const localDevFlow = "http://localhost:5173"

export const getFindProperty = async (params) => {
    try {
        const response = await axios.get(`${dynamicApiUrl}/property/find?${params}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching data", error);
        throw error;
    }
};

export const sendData = (query) => {
    console.log("@@@window.location.href",window.location.href)
   // if (response && response.property) {
        if (window.location.href.includes('development') || window.location.href.includes('localhost')) {
            window.open(`${localDevFlow}/map-search/?${createSearchParams(query)}`);
        } else {
            window.open(`${dynamicRouteUrl}/map-search/?${createSearchParams(query)}`);
        }
    //} else return null
}