import {Stack, Alert, AlertIcon} from '@chakra-ui/react'
import {motion} from 'framer-motion'
function PropertyNotFound(){

    return (
        <motion.div
            initial={{ opacity: 0}}
            animate={{ opacity: 1 }}
            transition={{repeat: 1, repeatDelay: 4, repeatType: 'reverse'}}>
            <Alert status='error'>
                <AlertIcon />
                BidMax coming to your area soon!
            </Alert>
        </motion.div>
    )
}

export default PropertyNotFound