import AddressInput from './components/AddressInput';
import { ChakraProvider, Stack} from '@chakra-ui/react' 
import React, {useEffect} from 'react';
function App() {

  const loadGoogleMapsScript = () => {
    const existingScript = document.querySelector(`script[src*="maps.googleapis.com"]`);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_API_KEY}&libraries=places&loading=async`;
      script.async = true;
      document.head.appendChild(script);
      script.onload = () => {
        // console.log('Google Maps script loaded successfully');
      };
    } else {
      // console.log('Google Maps script already loaded');
    }
  };
  
  useEffect(() => {
    loadGoogleMapsScript();
  }, []);

  return (
    <ChakraProvider>
      <div style={{
        background: 'transparent',
      }}>
        <AddressInput/>
      </div>
    </ChakraProvider>
    
  );
}

export default App;
