import React, {useRef, useEffect, useState} from "react";
import './styles.css';
import {getFindProperty, sendData} from "./functions";
import {Button} from '@chakra-ui/react'
import PropertyNotFound from "../PropertyNotFound";

function AddressInput() {
    const inputRef = useRef(null);
    const [address, setAddress] = useState('')
    const [fullAddress, setFullAddress] = useState('')
    const [loading, setLoading] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const [lat, setLat] = useState('')
    const [lng, setLng] = useState('')
    const [streetName, setStreetName] = useState('')
    const [streetNumber, setStreetNumber] = useState('')
    const [apartment, setApartment] = useState('')
    const [query, setQuery] = useState({
        address: "",
        bounds: null,
        centerCoords: null,
    })

    useEffect(() => {
        if (window.google && window.google.maps) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                setQuery({
                    address: place.formatted_address,
                    bounds: JSON.stringify(place.geometry.viewport),
                    centerCoords: JSON.stringify(place.geometry.location),
                })
                setFullAddress(place.formatted_address);
                const number = place.address_components.filter((item) => {
                    if (item.types.includes('street_number')) {
                        return (item)
                    }
                })
                const name = place.address_components.filter((item) => {
                    if (item.types.includes('route')) {
                        return (item)
                    }
                })

                const apt = place.address_components.filter((item) => {
                    if (item.types.includes('subpremise')) {
                        return (item)
                    }
                })
                setStreetNumber(number[0]?.long_name)
                setStreetName(name[0]?.long_name)
                setFullAddress(place.formatted_address);
                if (apt.length > 0) {
                    setApartment(apt[0]?.long_name)
                }
                if (place.geometry) {
                    const lat = place.geometry.location.lat();
                    const lng = place.geometry.location.lng();
                    setLat(lat)
                    setLng(lng)
                } else {
                    //'No geometry information available for the selected place.'
                }
            });
        }
    }, [address]);

    useEffect(() => {
        setTimeout(() => {
            setNotFound(false)
        }, 10000)
    }, [notFound])

    useEffect(() => {
        if (fullAddress.length > 1) {
            handleSearch()
        }
    }, [fullAddress])

    const handleSearch = async () => {
        setLoading(true)
        let params
        if (apartment.length > 0) {
            params = new URLSearchParams({
                address: fullAddress,
                lat: lat,
                lng: lng,
                street: streetName,
                streetnumber: streetNumber,
                unit: apartment
            }).toString();
        } else {
            params = new URLSearchParams({
                address: fullAddress,
                lat: lat,
                lng: lng,
                street: streetName,
                streetnumber: streetNumber
            }).toString();
        }
        const response = await getFindProperty(params);
        if (response.isSuccess && response.property != null) {
            setQuery(prev=>({
                ...prev,
                locationId: response.property.uniqueId,
            }))
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const handleSend = async (query) => {
        setNotFound(false)
 
            sendData(query)

    }

    return (
        <div id='full-container'>
            <div id='widget-container'>
                <input
                    id={'address-input'}
                    value={fullAddress ? fullAddress : address}
                    onChange={(e) => {
                        setFullAddress('')
                        setAddress(e.target.value)
                    }}
                    ref={inputRef}
                    type="text"
                    placeholder="Start typing address..."/>
                <Button
                    isDisabled={fullAddress.length < 1}
                    id={'search-button'}
                    p={'20px'}
                    mb={'10px'}
                    isLoading={loading}
                    onClick={() => handleSend(query)}>
                    Find Properties
                </Button>
            </div>
            {notFound ? <PropertyNotFound/> : null}
        </div>
    );
}

export default AddressInput